import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { UserModel } from '../models/UserModel';
import { LoginForm } from '../models/LoginForm';
import { UserServiceService } from '../Service/user-service.service';

@Injectable({
  providedIn: 'root'
})
export class UserProvider {
  public errorMessage: string;
  public loggedInUser: UserModel = new UserModel;
  public userRole: number;
  public loading : boolean;
  constructor(private userService: UserServiceService,
    private toastr: ToastrService, private router: Router) {

  }
  userLogin(LoginForm: LoginForm): void {
    this.userService.userLogin(LoginForm).subscribe(
      (response) => {
        this.toastr.success("Logged in Successfully", "Success");
        if (response.success) {
          localStorage.setItem('access-token', response.data.access_token);
          localStorage.setItem('userRole', response.data.user_role);
          localStorage.setItem('userId', response.data.id);
          localStorage.setItem('userName', response.data.user_name);
          this.loading = true;
          let user = response.data;
          this.loggedInUser.Token = user.access_token;
          this.loggedInUser.isAuditor = false;
          this.loggedInUser.isModerator = true;
          this.loggedInUser.isAgent = false;
          this.loggedInUser.isTeamLeader = false;
          this.userRole = response.data.user_role;
          localStorage.setItem('userRole', response.data.user_role); // Need to add valid conditions;
           if (response.data.user_role == 8) {
            this.loggedInUser.isTeamLeader = true;
            this.router.navigate(["/team-leader"]);
          }
          else if(response.data.user_role == 9){
            this.router.navigate(["/agent/posm-tasks"]);
          }
          else if(response.data.user_role == 13){
            this.router.navigate(["/agent-call-center/call-posm-tasks"]);
          }
          else{
            this.toastr.error("Don't have access to any module", "Error");
          }
        }
        else {
          localStorage.removeItem('access-token');
          localStorage.removeItem('userRole');
          localStorage.removeItem('userId');
        }

      },
      error => {
        if (error.status == 422) {
          this.toastr.error(" Incorrect username or password ", "Error");
          this.errorMessage = ' Incorrect username or password ';
          this.loading = false;
        }
      }
    )
  };
}
