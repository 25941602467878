import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AgentsComponent } from './agents.component';
import { NotfoundComponent } from '../notfound/notfound.component';

const routes: Routes = [
  {
    path: '',
    component: AgentsComponent,
    children: [
      {
        path: 'posm-tasks',
        loadChildren: () => import('../agents/Components/category/posm/posm-task/posm-task.module')
          .then(m => m.PosmTaskModule)
      },
      {
        path: 'cooler-tasks',
        loadChildren: () => import('../agents/Components/category/cooler/cooler-task/cooler-task.module')
          .then(m => m.CoolerTaskModule)
      },
      {
        path: 'mystery-tasks',
        loadChildren: () => import('../agents/Components/category/mystery/mystery-task/mystery-task.module')
          .then(m => m.MysteryTaskModule)
      },
      {
        path: 'invoice-tasks',
        loadChildren: () => import('../agents/Components/category/invoice/invoice-task/invoice-task.module')
          .then(m => m.InvoiceTaskModule)
      },
      {
        path: 'contest-tasks',
        loadChildren: () => import('../agents/Components/category/contest/contest-task/contest-task.module')
          .then(m => m.ContestTaskModule)
      },
      {
        path: 'engagement-posm-tasks',
        loadChildren: () => import('../agents/Components/category/engagements/engagement-posm-task/engagement-posm-task.module')
          .then(m => m.EngagementPosmTaskModule)
      },
      {
        path: 'engagement-picture-tasks',
        loadChildren: () => import('../agents/Components/category/engagements/engagement-picture-task/engagement-picture-task.module')
          .then(m => m.EnagementPictureTaskModule)
      },
      {
        path: 'outlet-search',
        loadChildren: () => import('../agents/Components/category/outlet/outlet-search/outlet-search.module')
          .then(m => m.OutletSearchModule)
      },
      { path: '**', component: NotfoundComponent }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AgentsRoutingModule { }
